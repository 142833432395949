



































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Watch, Inject } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import { IPrismThemeSettings, ISettingsModel, IUserInfo } from "./models";
import {
    AppActionTypes,
    AppGetterTypes,
    GetClientItemsAction,
    GetClientsAction,
    GetProjectItemsAction,
    GetSettingsAction,
    GetUserItemsAction
} from "./store";
import { errorLog } from "./logging";
import { IRouteNames } from "./router";

import LayoutNavigation from "./components/LayoutNavigation.vue";
import LayoutTimeTotals from "./components/LayoutTimeTotals.vue";
import DateLabel from "./components/DateLabel.vue";
import AuthenticationChecker from "./components/AuthenticationChecker.vue";
import NotificationItemDropdown from "./components/NotificationItemDropdown.vue";
import SideMenu from "./components/SideMenu.vue";
import Breadcrumbs from "./components/Breadcrumbs.vue";
import UnauthorizedModal from "./components/UnauthorizedModal.vue";
import UpdateAvailableNotification from "./components/UpdateAvailableNotification.vue";
import { GLOBAL_ERROR_EVENT } from "./config";
import { NetworkErrorEventModel } from "./ajax";

@Component({
    components: {
        "layout-navigation": LayoutNavigation,
        "layout-time-totals": LayoutTimeTotals,
        "date-label": DateLabel,
        "side-menu": SideMenu,
        "authentication-checker": AuthenticationChecker,
        "notification-item-dropdown": NotificationItemDropdown,
        "breadcrumbs": Breadcrumbs,
        "unauthorized-modal": UnauthorizedModal,
        "update-available-notification": UpdateAvailableNotification
    }
})
export default class App extends Vue {
    @Inject()
    routePaths: IRouteNames;

    @Getter(AppGetterTypes.user)
    user!: IUserInfo;

    @Getter(AppGetterTypes.settings)
    settings!: ISettingsModel;

    @Getter(AppGetterTypes.title)
    title: string;

    @Getter(AppGetterTypes.version)
    version: string;

    @Getter(AppGetterTypes.prismThemeSettings)
    prismThemeSettings: IPrismThemeSettings;

    @Action(AppActionTypes.getSettings)
    getSettings: GetSettingsAction;

    @Action(AppActionTypes.getClients)
    getClients: GetClientsAction;

    @Action(AppActionTypes.getClientItems)
    getClientItems: GetClientItemsAction;

    @Action(AppActionTypes.getProjectItems)
    getProjectItems: GetProjectItemsAction;

    @Action(AppActionTypes.getUserItems)
    getUserItems: GetUserItemsAction;

    globalErrorMessage: string | null = null;

    loading = true;

    get isAuthenticated() {
        return this.user && this.user.id > 0;
    }

    get fullName(): string {
        if (this.user && this.user.fullName) {
            return this.user.fullName;
        }

        return "";
    }

    get photoUrl(): string {
        return "/api/ProfilePhoto";
    }

    get signOutUrl(): string {
        if (this.settings) {
            return this.settings.signOutUrl;
        }

        return "";
    }

    errorHandler(error: Error): void {
        this.globalErrorMessage = error.message;
    }

    networkErrorHandler(
        event: CustomEvent<NetworkErrorEventModel> | Event
    ): void {
        if (event instanceof CustomEvent) {
            this.globalErrorMessage = event.detail.message;
        }
    }

    /**
     * sets the prism color theme based on the users preferred color mode
     */
    setColorTheme(): void {
        let link = document.getElementById("prism-style-sheet") as HTMLLinkElement;
        if (link) {
            if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                link.href = this.prismThemeSettings.darkUrl;
            }
            else {
                link.href = this.prismThemeSettings.lightUrl;
            }
        }
    }

    year = new Date().getFullYear();

    mounted() {
        this.$root.$on(GLOBAL_ERROR_EVENT, this.errorHandler);
        window.addEventListener(
            NetworkErrorEventModel.EVENT_NAME,
            this.networkErrorHandler
        );

        this.getSettings().then((settings) => {
            const user = settings.user;
            errorLog.setUser({
                identifier: user.id.toString(),
                email: user.email,
                firstName: user.firstName,
                fullName: user.fullName,
                isAnonymous: false
            });
        });

        // sets the users preferred color mode on page load
        this.setColorTheme();
        // add event listener for when when the user changes their preferred color scheme
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", event => {
            this.setColorTheme();
        });

        // pre-load clients, projects, users, etc
        Promise.all([
            this.getClients(),
            this.getClientItems(),
            this.getProjectItems(),
            this.getUserItems()
        ]).finally(() => (this.loading = false));
    }

    @Watch("title", { immediate: true })
    updateTitle(val: string) {
        document.title = `${val} | Elite Time Tracker`;
    }
}
