import Vue from "vue";
import Vuex from "vuex";

import { HubConnection } from "@microsoft/signalr";

import { SignalRHubHelper } from "../ajax";
import { TimeChargeModel } from "../models/time";
import actions, {
    AppActionTypes,
    GetClientItemsAction,
    GetClientsAction,
    GetProjectItemsAction,
    GetSettingsAction,
    GetUserAction,
    GetUserItemsAction
} from "./actions";
import getters, { AppGetterTypes } from "./getters";
import IAppState from "./IAppState";
import mutations, { AppMutationTypes, SetTimeEntryMutation } from "./mutations";

// https://vuex.vuejs.org/
Vue.use(Vuex);

const initialState: IAppState = {
    version: process.env.VUE_APP_VERSION || "",
    prismThemeSettings: {
        lightUrl: process.env.VUE_APP_PRISM_LIGHT_MODE_CSS_THEME_URL || "",
        darkUrl: process.env.VUE_APP_PRISM_DARK_MODE_CSS_THEME_URL || ""
    },
    settings: {
        isAuthenticated: false,
        phoneNumberPattern: "",
        emailPattern: "",
        signInUrl: "",
        signOutUrl: "",
        user: { id: 0, roles: [], userType: 0 },
        taskStatusItems: [],
        activeTaskStatusItems: [],
        externalProjectManagementItems: [],
        internalLinkTypeItems: [],
        timeEntryTypeItems: [],
        taskFeedbackEnvironmentItems: [],
        clientProjectEnvironmentItems: [],
        stateItems: [],
        newTaskStatusId: 0,
        closedTaskStatusId: 0
    },
    user: { id: 0, userType: 0, roles: [] },
    timeEntry: new TimeChargeModel(),
    updateAvailable: false,
    clients: [],
    title: window.document.title,
    clientItems: [],
    userItems: [],
    projectItems: {}
};

const store = new Vuex.Store({
    state: initialState,

    // https://vuex.vuejs.org/guide/getters.html
    getters: getters,

    // https://vuex.vuejs.org/guide/mutations.html
    mutations: mutations,

    // https://vuex.vuejs.org/guide/actions.html
    actions: actions
});

// Create a client and project hub connection to update the client/project items when they're changed:
const clientHubConnection: HubConnection =
    SignalRHubHelper.createConnection("/hub/ClientHub");
const projectHubConnection: HubConnection =
    SignalRHubHelper.createConnection("/hub/ProjectHub");

// Reset the client items upon a client being updated (ex: someone else just updated a client's name):
clientHubConnection.on("ClientUpdated", () => {
    store.commit(AppMutationTypes.setClientItems, []);
    store.dispatch(AppActionTypes.getClientItems);
});

// Reset the project items upon a project being updated (ex: someone else just updated a projects's name):
projectHubConnection.on("ProjectUpdated", () => {
    store.commit(AppMutationTypes.setProjectItems, {});
    store.dispatch(AppActionTypes.getProjectItems);
});

clientHubConnection.start();
projectHubConnection.start();

export default store;

export {
    AppGetterTypes,
    AppActionTypes,
    GetSettingsAction,
    GetUserAction,
    SetTimeEntryMutation,
    GetProjectItemsAction,
    GetUserItemsAction,
    GetClientsAction,
    GetClientItemsAction
};
