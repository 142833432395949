import { DirectiveOptions, VNode } from "vue";
import { DirectiveBinding } from "vue/types/options";

const CodeDirective: DirectiveOptions = {
    bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
        // We call highlightAllUnder synchronously because async is slower and probably not needed.
        // https://prismjs.com/faq.html#why-is-asynchronous-highlighting-disabled-by-default
        //
        // Also synchronous code does not use web workers which prevents this error:
        // "Failed to construct 'Worker': Script at 'https://cdn.jsdelivr.net/npm/prismjs@1.29.0/components/prism-core.min.js' cannot be accessed from origin 'https://localhost:44345'."
        // Web workers from a different origin are a security risk and therefore blocked.
        window.Prism.highlightAllUnder(el, false);
    }
};

export default CodeDirective;
